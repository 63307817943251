@use '../../styleguide/colors.scss';
@use '../../styleguide/vars.scss';
@use '../../styleguide/mixins.scss';

.wishlistPopup {
  display: grid;
  grid-auto-flow: row;
  padding: 8px 16px;
  overflow-y: auto;
  color: colors.$default-text;

  @media screen and (min-width: vars.$extra-large) {
    padding: 8px;
  }

  .menuHeader {
    background: transparent;
    border-bottom: 0;
    margin-bottom: 15px;
    color: colors.$default-text;

    @media screen and (min-width: vars.$extra-large) {
      h3 {
        font-size: 21px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 114.286% */
        letter-spacing: 0.1px;
        font-stretch: condensed;
      }
    }

    button {
      color: colors.$default-text;
      font-size: 0.875rem;
    }
  }
}

.wishlistCard {
  @include mixins.dropShadowCard();

  &:nth-child(4) {
    @media screen and (min-width: vars.$extra-large) {
      display: none;
    }
  }
}

div.wishlistPopupWrapper {
  width: auto;
  padding: 16px;
  margin-top: 22px;
}

.productsList {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
  margin-bottom: 16px;

  @media screen and (min-width: vars.$extra-large) {
    grid-auto-flow: column;
    grid-auto-columns: 220px;
    grid-template-columns: initial;
  }
}

.scroll {
  overflow: scroll;
}

a.productLink {
  display: grid;
  grid-template-columns: max-content auto;
  align-items: flex-start;
}

.callToActionBtn {
  color: colors.$white;
  width: 100%;
  text-decoration: none;
}

.empty {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;

  svg {
    font-size: 12rem;
    margin-bottom: 20px;
  }
}

.backIcon,
.closeIcon {
  @media screen and (min-width: vars.$extra-large) {
    display: none;
  }
}

.emptyButton {
  padding: 0 16px;
}

.emptyWishlistNotice {
  margin: 8px 0 12px 0;
  font-stretch: condensed;
  line-height: 22px;
  letter-spacing: 0.2px;
  max-width: 295px;
  text-align: center;
}
