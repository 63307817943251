@use '../../styleguide/colors.scss';
@use '../../styleguide/vars.scss';

.productCard {
  background-color: colors.$white;
  border-radius: 4px;
  cursor: pointer;
  padding: 16px 8px;
  position: relative;

  @media screen and (min-width: vars.$medium) {
    padding: 24px 16px 16px 16px;
  }
  @media screen and (min-width: vars.$extra-large) {
    height: 100%;

    &:hover {
      .delete {
        display: block;
      }
    }
  }
}

.productCard a {
  align-items: center;
  display: flex;
  flex-direction: column;
  text-decoration: none;
  width: 100%;
}

.imageContainer {
  align-items: center;
  justify-content: center;
  display: flex;
  width: 100%;
  height: 90px;
  margin-bottom: 16px;

  @media screen and (min-width: vars.$medium) {
    height: 120px;
  }

  img {
    max-width: 100%;
    max-height: 100%;
  }
}

.content {
  display: flex;
  flex-direction: column;
  width: 100%;
  color: var(--fill-black-hover, #555);
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: TT Prosto Sans Condensed;
  font-stretch: condensed;
  font-style: normal;
}

.name {
  font-stretch: condensed;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.2px;
  margin-bottom: 4px;
  height: 44px;
}

.price {
  font-size: 21px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.1px;
  height: initial;
  margin: 0;

  @media screen and (min-width: vars.$medium) {
    font-size: 24px;
    line-height: 28px;
  }
}

.shop {
  color: colors.$secondary-color;
  font-stretch: condensed;
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-top: vars.$nodeSpacing;
}

.dots {
  position: absolute;
  right: 10px;
  width: 10px;
  top: 50%;
  transform: translateY(-50%);
  height: 30px;
  background-image: radial-gradient(circle, colors.$dark-grey 1px, colors.$white 3px);
  background-size: 100% 33.33%;

  @media screen and (min-width: vars.$extra-large) {
    display: none;
  }
}

.productOverlay {
  bottom: 0;
  left: 0;
  right: 0;
  top: initial;
  transform: initial;

  @media screen and (min-width: vars.$medium) {
    left: 50%;
  }
}

.overlayContent {
  background-color: colors.$white;
  padding: 15px 10px;
  width: 100%;

  p {
    align-items: center;
    display: flex;
    font-size: 1.125rem;
  }

  svg {
    margin-right: 15px;
  }
}

.delete {
  display: none;

  @media screen and (min-width: vars.$extra-large) {
    background-color: colors.$white;
    color: colors.$secondary-text;
    padding: 5px;
    position: absolute;
    right: 15px;
    top: 15px;
  }
}

.heart {
  color: colors.$default-text;
  outline: none;
  position: absolute;
  right: 15px;
  top: 15px;
}

.liked {
  color: #d0021b;
}

.divider {
  border: none;
  border-top: 1px solid colors.$grey;
}

.unavailableProductImg {
  fill: none;
}

.adultContentPic {
  color: colors.$primary-color;
}

.toggleAdultContent {
  font-stretch: condensed;
  color: colors.$primary-color;
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 4px;
}