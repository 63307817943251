@use '../../styleguide/colors.scss';

.menu {
  background-color: colors.$white;
  border-radius: 5px;
  box-shadow: 0 8px 8px 0 rgba(0, 0, 0, 0.15);
  padding: 15px;
  position: absolute;
  right: 0;
  top: 80px;
  width: 360px;
  z-index: 1;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.5s ease, visibility 0.5s ease, transform 0.5s ease;
  transform-origin: top center;
}

.visible {
  opacity: 1;
  visibility: visible;
}
